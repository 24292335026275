"use client";

import "./Navbar.scss";

import { useState } from "react";
import Link from "next/link";

import NavbarDropdown from "./NavbarDropdown";

const Navbar = ({ navbarLinks }: any) => {
	const [expandedDropdowns, setExpandedDropdowns] = useState<{
		[key: string]: boolean;
	}>({});
	if (!navbarLinks?.length) return null;
	const toggleDropdown = (index: number) => {
		const id = `desktop-nav-link-${index}`;
		setExpandedDropdowns((prevState: any) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};
	return (
		<nav className='desktop' aria-label='main'>
			<ul className='level-1'>
				<li>
					<Link className='level-1-link' href='/'>
						Home
					</Link>
				</li>
				{navbarLinks.map(
					({ sys, fields }: any, index: number) =>
						fields && (
							<li
								key={sys?.id}
								onMouseEnter={() => toggleDropdown(index)}
								onMouseLeave={() => toggleDropdown(index)}
							>
								<Link
									href={fields?.href}
									id={`desktop-nav-link-${index}`}
									className='level-1-link'
									role='button'
									aria-expanded={
										expandedDropdowns[
											`desktop-nav-link-${index}`
										] || false
									}
									aria-controls={`desktop-nav-dropdown-${index}`}
									tabIndex={0}
								>
									{fields?.title}
								</Link>
								<div
									className='sub-nav-container'
									id={`desktop-nav-dropdown-${index}`}
									aria-expanded={
										expandedDropdowns[
											`desktop-nav-link-${index}`
										] || false
									}
									aria-labelledby={`desktop-nav-link-${index}`}
								>
									<NavbarDropdown
										columnLinks={fields?.childLinks}
									/>
								</div>
							</li>
						),
				)}
			</ul>
		</nav>
	);
};

export default Navbar;
