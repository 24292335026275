const BynderImage = ({ bynderAsset }: any) => {
	const { thumbnails, width, height, name } = bynderAsset;
	return (
		<img
			src={thumbnails?.webimage}
			height={height}
			width={width}
			alt={name}
		/>
	);
};

export default BynderImage;
