"use client";

import React, { Suspense, useEffect, useRef, useState } from "react";
import Link from "next/link";

import "./MobileNavbar.scss";

import Image from "next/image";

import HeaderSearchBar from "@components/Header/Search/HeaderSearchBar";
import OutsideClicker from "@hooks/useClickOutside";
import {
	billPayIcon,
	callIcon,
	closeIcon,
	doctorsIcon,
	hamburgerMenu,
	leftAngleIcon,
	locationsIcon,
	myChartIcon,
	rightAngleIcon,
} from "@icons/index";
import { childrensLogo } from "@logos/index";

const ActionButtons = ({ actions }: any) => (
	<ul className='action-buttons'>
		{actions.map(({ href, title, svg }: any) => (
			<li key={title}>
				<a href={href}>
					<Image
						src={svg}
						alt={`${title} icon`}
						width={18}
						height={30}
						priority
					/>
					{title}
				</a>
			</li>
		))}
	</ul>
);

const NestedLinks = ({
	links,
	displayThirdLevel,
	handleSecondLevelClick,
}: any) => {
	const scrollRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (displayThirdLevel !== null && scrollRef?.current) {
			scrollRef?.current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [displayThirdLevel]);

	if (!links?.length) {
		return null;
	}

	return links.map(
		(lvl2: any, lvl2Index: number) =>
			lvl2.fields && (
				<li key={lvl2.sys.id}>
					{!lvl2?.fields?.navImage &&
					lvl2?.fields?.childLinks?.length > 0 ? (
						<>
							<button
								className='level-2-item'
								aria-expanded={displayThirdLevel === lvl2Index}
								aria-controls={`level-3-section-${lvl2Index}`}
								onClick={() =>
									handleSecondLevelClick(lvl2Index)
								}
							>
								{lvl2.fields.title}
								<Image
									src={rightAngleIcon}
									alt='Right angle icon'
									width={10}
									height={15}
									priority
								/>
							</button>
							<ul className='level-3-list'>
								<li ref={scrollRef}>
									<button
										className='level-3-item'
										onClick={() =>
											handleSecondLevelClick(lvl2Index)
										}
									>
										<Image
											src={leftAngleIcon}
											alt='Left angle icon'
											width={10}
											height={15}
											priority
										/>
										{lvl2.fields.title}
									</button>
								</li>
								{lvl2.fields?.childLinks?.map(
									(subItem: any, index: number) =>
										subItem.fields && (
											<li key={subItem.sys.id}>
												<Link
													className='level-3-item'
													href={subItem.fields.href}
												>
													{subItem.fields.title}
												</Link>
											</li>
										),
								)}
							</ul>
						</>
					) : (
						<Link className='nav-item' href={lvl2.fields.href}>
							{lvl2.fields.title}
						</Link>
					)}
				</li>
			),
	);
};

const NavbarSections = ({
	sections,
	expandedSection,
	handleSectionClick,
	displayThirdLevel,
	handleSecondLevelClick,
}: any) => {
	return (
		<ul className='level-1-list'>
			<li key='Home'>
				<Link className='level-1-item' href='/'>
					Home
				</Link>
			</li>
			{sections.map(
				(section: any, index: number) =>
					section.fields && (
						<li key={section.sys.id}>
							<button
								className='level-1-item'
								aria-expanded={expandedSection === index}
								aria-controls={`section-${index}`}
								onClick={() => handleSectionClick(index)}
							>
								{section.fields.title}
								<span>
									{expandedSection === index ? "-" : "+"}
								</span>
							</button>
							<ul
								className='level-2-list'
								id={`section-${index}`}
							>
								<NestedLinks
									links={section.fields.childLinks}
									displayThirdLevel={displayThirdLevel}
									handleSecondLevelClick={
										handleSecondLevelClick
									}
								/>
							</ul>
						</li>
					),
			)}
			<li>
				<Link href="/patient-families/billing-and-insurance/cost-estimates" >Pricing Transparency</Link>
			</li>
		</ul>
	);
};

const MobileNavbar = ({ navbarLinks, isNavOpen, toggleCB }: any) => {
	const [displayThirdLevel, setDisplayThirdLevel] = useState<number | null>(
		null,
	);
	const [expandedSection, setExpandedSection] = useState<number | null>(null);

	if (!navbarLinks.length) return null;

	const handleSectionClick = (index: number) =>
		setExpandedSection((prev) => (prev === index ? null : index));
	const handleSecondLevelClick = (index: number) =>
		setDisplayThirdLevel((prev) => (prev === index ? null : index));

	const actionList = [
		{ href: "tel:+18444244537", title: "Call", svg: callIcon },
		{
			href: "https://www.childrens.com/patient-families/parent-resources/mychart-guide",
			title: "MyChart",
			svg: myChartIcon,
		},
		{
			href: "https://www.childrens.com/patient-families/billing-and-insurance/pay-my-bill",
			title: "Bill Pay",
			svg: billPayIcon,
		},
		{
			href: "https://www.childrens.com/search?q=*&ct=ls&start=0&rows=10&sort=relv",
			title: "Locations",
			svg: locationsIcon,
		},
		{
			href: "https://www.childrens.com/search?q=*&ct=ds&start=0&rows=10&sort=relv",
			title: "Doctors",
			svg: doctorsIcon,
		},
	];

	return (
		<>
			<button className='mobile-nav-toggle' onClick={toggleCB}>
				<span className='visually-hidden'>
					Toggle mobile search and navigation
				</span>
				<Image
					src={hamburgerMenu}
					alt='mobile menu icon'
					width={21}
					height={24}
					priority
				/>
			</button>

			<div className={`off-canvas-menu ${isNavOpen ? "active" : ""}`} />
			<OutsideClicker action={() => isNavOpen && toggleCB()}>
				<nav
					className={`mobile-navbar ${isNavOpen ? "open" : ""}`}
					aria-label='mobile'
				>
					<div className='header-bar'>
						<Image
							src={childrensLogo}
							alt="Children's Logo"
							width={143}
							height={36}
							priority
						/>
						<button
							onClick={toggleCB}
							className='mobile-nav-toggle'
							aria-pressed={isNavOpen}
							aria-expanded={isNavOpen}
							aria-controls='off-canvas-menu'
						>
							<span className='visually-hidden'>
								Toggle mobile search and navigation
							</span>
							<Image
								src={closeIcon}
								alt='close-icon'
								width={20}
								height={20}
								priority
							/>
						</button>
					</div>
					<div className='scroll-wrapper'>
						<div className='mobile-nav-header'>
							<div className='mobile-header-search'>
								<div className='mobile-searchbar-wrapper'>
									<Suspense fallback={null}>
										<HeaderSearchBar />
									</Suspense>
								</div>
								<ActionButtons actions={actionList} />
							</div>
						</div>
						<NavbarSections
							sections={navbarLinks}
							expandedSection={expandedSection}
							handleSectionClick={handleSectionClick}
							displayThirdLevel={displayThirdLevel}
							handleSecondLevelClick={handleSecondLevelClick}
						/>
					</div>
				</nav>
			</OutsideClicker>
		</>
	);
};

export default MobileNavbar;
