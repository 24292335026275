"use client";

import React from "react";

import "./ClientLink.scss";

import type { ReactNode } from "react";
import Link from "next/link";
import {
	useParams,
	usePathname,
	useRouter,
	useSearchParams,
} from "next/navigation";

const ClientLink = ({
	href,
	children,
	variant,
	...props
}: {
	children: ReactNode;
	href: string;
	variant?: string;
}) => {
	const pathName = usePathname();
	const params = useParams();
	const searchParams = useSearchParams();
	const router = useRouter();
	return (
		<Link
			href={href}
			className={`client-link-base ${variant || ""}`}
			{...props}
		>
			{children}
		</Link>
	);
};

export default ClientLink;
