"use client";

import React, { Suspense, useCallback, useState } from "react";

import LanguageSwitcher from "@components/Header/LanguageSwitcher/LanguageSwitcher";
import MobileNavbar from "@components/Header/Navbar/MobileNavbar/MobileNavbar";
import HeaderSearchBar from "@components/Header/Search/HeaderSearchBar";

const HeaderInputContainer = ({ navbarLinks }: any) => {
	const [isNavbarOpen, setNavbarOpen] = useState(false);

	const toggleNavbar = useCallback(() => {
		setNavbarOpen(!isNavbarOpen);
	}, [isNavbarOpen]);

	return (
			<>
				<Suspense fallback={null}>
					<HeaderSearchBar className="desktop-search" />
				</Suspense>
				<div onClick={toggleNavbar} className="search-bar mobile-search-icon">
					<label htmlFor="mobile-search-submit" className="visually-hidden">
						Search
					</label>
					<input
							id="mobile-search-submit"
							className="search-submit"
							type="text"
							aria-label="Search"
					/>
				</div>
				<LanguageSwitcher />
				<MobileNavbar
						isNavOpen={isNavbarOpen}
						toggleCB={toggleNavbar}
						navbarLinks={navbarLinks}
				/>
			</>

	);
};

export default HeaderInputContainer;
