"use client";

import React, { useRef, useState } from "react";

import ContentfulRichText from "@/components/ContentfulRichText/ContentfulRichText";

import "./FAQAccordionItem.scss";

import { IFaqItemFields } from "@/lib/contentful/types/contentful";
import useToggle from "@hooks/useToggle";

const FAQAccordionItem: React.FC<IFaqItemFields> = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useToggle(false);
	const [height, setHeight] = useState<string | number>("0px");
	const content = useRef<HTMLDivElement | null>(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
		setHeight(isOpen ? "0px" : `${content?.current?.scrollHeight}px`);
	};

	return (
		<div
			role='presentation'
			className='accordion-item'
			onClick={toggleAccordion}
		>
			<button>{isOpen ? "-" : "+"}</button>
			<h3>{question}</h3>
			<div
				ref={content}
				style={{ maxHeight: height }}
				className={`accordion-menu ${isOpen ? "active" : ""}`}
			>
				<ContentfulRichText
					document={answer}
					className='accordion-menu'
				/>
			</div>
		</div>
	);
};

export default FAQAccordionItem;
