"use client";

import React, { useEffect, useState, useCallback } from "react";
import Image from "next/image";
import { useRouter, usePathname } from "next/navigation";

import "./LanguageSwitcher.scss";

import OutsideClicker from "@hooks/useClickOutside";
import { translationGlobe } from "@icons/index";
import { isDevelopment } from "@/lib/environment";
import { useAppContext } from "@components/ContextProvider";
import { env } from "@/env";

const LanguageSwitcher = () => {
	const { appContext } = useAppContext();
	const [isSpanish, setIsSpanish] = useState(false);
	const [languageMenuVisible, setLanguageMenuVisible] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const [isActive, setIsActive] = useState(false);

	const hasTranslation: boolean = appContext.isTranslated;
	const router = useRouter();
	const pathname = usePathname();

	const isOnSpanishSite = typeof window !== "undefined" && (window.location.hostname.includes(env.NEXT_PUBLIC_BASE_DOMAIN_ES.replace(/^https?:\/\//, "")));

	// Helper functions to handle cookies
	const getCookie = (name: string): string | null => {
		const cookieString = document.cookie;
		const cookies = cookieString.split("; ").reduce<Record<string, string>>((acc, curr) => {
			const [key, value] = curr.split("=");
			acc[key] = value;
			return acc;
		}, {});
		return cookies[name] || null;
	};

	const setCookie = (name: string, value: string) => {
		const isLocalhost = window.location.hostname === "localhost";
		const maxAge = 365 * 24 * 60 * 60; // seconds in a year
		document.cookie = `${name}=${value}; Path=/; Max-Age=${maxAge};${
				isLocalhost ? "" : " Domain=.childrens.com; Secure;"
		} SameSite=None;`;
	};

	const deleteCookie = (name: string) => {
		document.cookie = `${name}=; Path=/; Domain=.childrens.com; Max-Age=0;`;
	};

	// Fetch cookie on mount to set initial language state and handle redirection if needed
	useEffect(() => {
		const initializeLanguage = () => {
			const currentLanguage = getCookie("language");

			if (currentLanguage === "spanish" || isOnSpanishSite) {
				setIsSpanish(true);
				setSelectedLanguage("spanish");

				if (!isOnSpanishSite) {
					router.push(`${env.NEXT_PUBLIC_BASE_DOMAIN_ES}${pathname.startsWith("/") ? pathname : `/${pathname}`}`);
				}
			} else {
				setIsSpanish(false);
				setSelectedLanguage("english");
			}
		};

		if (pathname) {
			initializeLanguage()
		} else {
			console.error("Invalid dependencies:", { pathname });
		}
	}, [isOnSpanishSite, router, pathname]);

	// Redirect to selected language domain
	const redirectToLanguage = useCallback((toSpanish: boolean) => {

		if ((toSpanish && isOnSpanishSite) || (!toSpanish && !isOnSpanishSite)) {
			console.log("No redirect needed. Already on the correct domain.");
			return;
		}

		const baseUrl = toSpanish
				? env.NEXT_PUBLIC_BASE_DOMAIN_ES
				: isDevelopment()
						? "http://localhost:3000"
						: env.NEXT_PUBLIC_BASE_DOMAIN

		const safePathname = pathname?.startsWith("/") ? pathname : `/${pathname}`;
		const newUrl = `${baseUrl}${safePathname}`;

		if (toSpanish) {
			setCookie("language", "spanish");
		} else {
			deleteCookie("language");
		}

		router.push(newUrl);
		setLanguageMenuVisible(false);
	},[isOnSpanishSite, router, pathname]);

	const handleLanguageChange = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();
		redirectToLanguage(selectedLanguage === "spanish");
	};

	const handleLanguageToggle = useCallback((e: any) => {
		if (e.target && e.target.value) {
			setSelectedLanguage(e.target.value);
		}
		if (e.target.tagName === "IMG" || e.target.tagName === "BUTTON") {
			setIsActive(true);
		} else if (e.target.tagName === "SELECT") {
			setLanguageMenuVisible((prev) => !prev);
		}
	}, []);

	const handleOutsideClick = () => {
		setLanguageMenuVisible(false);
		setSelectedLanguage(isSpanish ? "spanish" : "english");
		setIsActive(false);
	};

	if (!hasTranslation && !isOnSpanishSite) {
		return null;
	}

	const languageOptions = isSpanish
			? [
				{ value: "spanish", label: "Español" },
				{ value: "english", label: "English" },
			]
			: [
				{ value: "english", label: "English" },
				{ value: "spanish", label: "Español" },
			];

	return (
			<div>
				<OutsideClicker action={handleOutsideClick}>
						<button onClick={handleLanguageToggle} className="mobile-language-toggle">
								<span className="svg-globe-icon">
										<Image src={translationGlobe} width={21} height={21} alt="Language Selection" priority />
								</span>
						</button>
						<div className={`language-switcher ${isActive ? "active" : ""}`}>
								<span className={`svg-globe-icon`} role="presentation">
									<Image src={translationGlobe} width={21} height={21} alt="Language Selection" priority />
								</span>

								{/* TBD if this is needed */}
								{/*{!hasTranslation && isOnSpanishSite && <p className="no-translation-message">No disponible en español</p>}*/}

								<form onSubmit={handleLanguageChange} className="OneLinkNoTx">
										<select value={selectedLanguage} onChange={handleLanguageToggle}>
												{languageOptions.map((option) => (
														<option key={option.value} value={option.value}>
															{option.label}
														</option>
												))}
										</select>
									{languageMenuVisible ? (
											<input type="submit" value={selectedLanguage === "spanish" ? "Cambiar" : "Go"} />
									) : null}
								</form>
						</div>
						</OutsideClicker>
			</div>
	);
};

export default LanguageSwitcher;
