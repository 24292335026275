import { useEffect, useRef, useState } from "react";

function useDebounce<T>(value: T, delay: number = 500): T {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const timerRef = useRef<NodeJS.Timeout | number>();

	useEffect(() => {
		timerRef.current = setTimeout(
			() => setDebouncedValue(value),
			delay,
		) as unknown as NodeJS.Timeout;

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current as NodeJS.Timeout);
			}
		};
	}, [value, delay]);

	return debouncedValue;
}

export default useDebounce;
