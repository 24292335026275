import { getLogger } from "@/lib/di";
import { FilteredSearchResults } from "@services/search-types";

interface Suggestion {
	type: "suggestion" | "path" | "page";
	value?: string;
	url?: string;
}

interface Page {
	id: string;
	value: string;
	url: string;
}

interface HighlightProperties {
	[key: string]: any;
}

interface Highlight {
	id: string;
	[key: string]: any;
}

interface Group {
	groupValue: string;
	docs: Page[];
}
/*
 * @function processSuggestionQueryData
 * @param data
 * */
const processSuggestionQueryData = (data: any): Suggestion[] => {
	const suggestShingleResults = (data.terms?.suggest_shingle || [])
		.slice(0, 5)
		.map((term: string) => ({
			type: "suggestion",
			value: term[0],
		}));

	const docResults = (data.response?.docs || [])
		.slice(0, 5)
		.map((doc: Page) => ({
			type: "page",
			value: doc.value,
			url: doc.url,
		}));

	return [...suggestShingleResults, ...docResults];
};

const groupHighlights = (data: {
	[key: string]: HighlightProperties;
}): Highlight[] => {
	return Object.entries(data)
		.filter(
			([_, properties]) =>
				typeof properties === "object" && properties !== null,
		)
		.map(([id, properties]) => ({ id, ...properties }));
};

const mergeDocsWithHighlights = (
	docs: Page[],
	highlights: Highlight[],
): Page[] => {
	return docs.map((doc) => {
		const highlightItem = highlights.find(
			(highlight) => highlight.id === doc.id,
		);
		return highlightItem
			? { ...doc, highlights: { ...highlightItem } }
			: doc;
	});
};

const mergeGroupedDocs = (
	groups: Group[],
	highlights: Highlight[],
): Group[] => {
	return groups.map((group) => {
		const mergedDocs = mergeDocsWithHighlights(group.docs, highlights);
		return { ...group, docs: mergedDocs };
	});
};

const processSearchQueryData = (data: any) => {
	const groups = (data.grouped?.mainCategory_s?.groups || []).map(
		(group: any) => ({
			groupValue: group.groupValue,
			docs: group.doclist.docs.map((item: any) => ({ ...item })),
		}),
	);

	const highlights = groupHighlights(data.highlighting);

	return {
		category: "grouped",
		count: data.grouped.mainCategory_s.matches,
		docs: mergeGroupedDocs(groups, highlights),
	};
};

const processCategoryData = (data: any, category: string) => {
	const docs = (data.response?.docs || []).map((item: any) => ({ ...item }));
	const highlights = groupHighlights(data.highlighting);
	return {
		category,
		count: data.response.numFound,
		docs: mergeDocsWithHighlights(docs, highlights),
	};
};

const formatDateForSolr = (dateStr: string): string => {
	try {
		const [year, month, day] = dateStr.split("-");
		const formattedDate = `${year}-${month}-${day}T00:00:00Z`;

		return formattedDate;
	} catch (error) {
		console.error("Error formatting date for Solr:", error);
		return dateStr;
	}
};

const buildFilteredSearchQuery = (options: any) => {
	const {
		q = "",
		mainCategory = "articles",
		subCategory_s,
		dateModifiedFrom,
		dateModifiedTo,
		programs_specialties_ss,
		conditions_ss,
		treatments_ss,
		sort,
		start,
		rows = 12,
	} = options;

	const fq = [`{!tag=mainCategory_s}mainCategory_s:"${mainCategory}"`];

	if (dateModifiedFrom && dateModifiedTo) {
		const fromDate = formatDateForSolr(dateModifiedFrom);
		const toDate = formatDateForSolr(dateModifiedTo);

		const dateFilter = `dateModified_dt:[${fromDate} TO ${toDate}]`;

		fq.push(dateFilter);
	}

	if (subCategory_s) {
		fq.push(`subCategory_s:"${subCategory_s}"`);
	}

	if (programs_specialties_ss) {
		fq.push(`programs_specialties_ss:"${programs_specialties_ss}"`);
	}

	if (conditions_ss) {
		fq.push(`conditions_ss:"${conditions_ss}"`);
	}

	if (treatments_ss) {
		fq.push(`treatments_ss:"${treatments_ss}"`);
	}

	const sortValue = getSolrSortValue(sort || "rel");

	const apiParams = {
		...(typeof q === 'string' && q.trim() ? { q: q.trim() } : {}),
		fq,
		start: start || 0,
		rows,
		sort: sortValue,
	};

	const friendlyFilteredParams = {
		q: q.trim(),
		mainCategory,
		dateRange:
			dateModifiedFrom && dateModifiedTo
				? { from: dateModifiedFrom, to: dateModifiedTo }
				: undefined,
	};

	return { apiParams, friendlyFilteredParams };
};

const getSolrSortValue = (sortOption: string): string => {
	switch (sortOption) {
		case "asc":
			return "asc";
		case "desc":
			return "desc";
		case "rel":
		default:
			return "relv";
	}
};

export const fetchSearchResults = async (query: string) => {
	const logger = getLogger();
	if (!query) {
		const errorMessage = "No Query String provided";
		logger.warn(errorMessage, null);
		return [];
	}

	const urlParams = new URLSearchParams(query);
	const { q, category, p } = Object.fromEntries(urlParams.entries());

	const row = "12";
	const params = {
		...(q ? { q } : {}),
		row,
		...(p ? { start: (+row * parseInt(p)).toString() } : {}),
		...(category
			? { fq: `{!tag=mainCategory_s}mainCategory_s:${category}` }
			: {}),
	};

	const urlWithParams =
		process.env.NEXT_PUBLIC_BASE_DOMAIN! +
		process.env.NEXT_PUBLIC_FUSION_SEARCH_API +
		"/Childrens?" +
		new URLSearchParams(params).toString();

	try {
		const response = await fetch(urlWithParams);

		if (!response.ok) {
			const errorMessage = `Error fetching search query, status: ${response.statusText}`;
			logger.error(errorMessage, null, { query, category });
			throw new Error(errorMessage);
		}

		const data = await response.json();

		return category
			? processCategoryData(data, category)
			: processSearchQueryData(data);
	} catch (error) {
		logger.error("Failed to fetch Search query", error, {
			query,
			category,
		});
		return [];
	}
};

export const fetchSearchSuggestions = async (
	query: string,
): Promise<Suggestion[]> => {
	const logger = getLogger();

	if (!query) {
		const errorMessage = "No Query String provided";
		logger.warn(errorMessage, null);
		return [];
	}

	const url =
		`${process.env.NEXT_PUBLIC_BASE_DOMAIN! + process.env.NEXT_PUBLIC_FUSION_SEARCH_API}/Childrens_Suggest?q=${query}` as string;

	try {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			const errorMessage = `Error: ${response.statusText}`;
			logger.error(errorMessage, null, { query });
			throw new Error(errorMessage);
		}

		if (!response.ok) {
			throw new Error(`Error: ${response.statusText}`);
		}

		const data = await response.json();
		const results = processSuggestionQueryData(data);

		return results.map((result) => ({
			type: result.type,
			value: result.value ?? "",
			url: result.url ?? "",
		}));
	} catch (error) {
		logger.error("Failed to fetch suggestions", error, { query });
		return [];
	}
};
export type FilterOptions = {
	q: string;
	mainCategory_ss?: string;
	subCategory_ss?: string;
	dateModifiedFrom?: string;
	dateModifiedTo?: string;
	programs_specialties_ss?: string;
	conditions_ss?: string;
	// NOTE: "rel" (relevance) | "asc" (oldest) | "desc" (newest)
	sort?: "rel" | "asc" | "desc";
	start?: number;
	rows?: number;
};
export const fetchFilteredSearchResults = async (
	filterOptions: FilterOptions,
): Promise<FilteredSearchResults> => {
	const logger = getLogger();

	const searchQuery = filterOptions.q?.trim() || "*:*";

	const { apiParams, friendlyFilteredParams } = buildFilteredSearchQuery({
		...filterOptions,
		q: searchQuery,
	});

	const apiUrl =
		process.env.NEXT_PUBLIC_BASE_DOMAIN! +
		process.env.NEXT_PUBLIC_FUSION_SEARCH_API +
		"/Childrens";

	const queryParams = Object.entries(apiParams)
		.flatMap(([key, value]) =>
			Array.isArray(value)
				? value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
				: `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number | boolean)}`,
		)
		.join("&");

	try {
		const response = await fetch(`${apiUrl}?${queryParams}`);

		if (!response.ok) {
			throw new Error(`Error fetching filtered search query, status: ${response.statusText}`);
		}

		const data = await response.json();
		
		return {
			status: "success",
			friendlyFilteredParams,
			fusion: data.fusion,
			docs: data.response.docs,
			facet_counts: data.facet_counts,
			total: data.response.numFound,
		} as FilteredSearchResults;
	} catch (error) {
		logger.error("Failed to fetch Search query", error, filterOptions);
		return {
			status: "error",
			friendlyFilteredParams,
			docs: [],
			facet_counts: { facet_queries: {}, facet_fields: {}, facet_ranges: {}, facet_intervals: {}, facet_heatmaps: {} },
			total: 0,
			fusion: { facet_labels: [] },
		};
	}
};