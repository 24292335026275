import { useState } from "react";

type ToggleFunction = (value?: boolean) => void;

export default function useToggle(
	defaultValue: boolean,
): [boolean, ToggleFunction] {
	const [value, setValue] = useState<boolean>(defaultValue);

	const toggleValue: ToggleFunction = (value?: boolean) => {
		setValue((currentValue) =>
			typeof value === "boolean" ? value : !currentValue,
		);
	};

	return [value, toggleValue];
}
