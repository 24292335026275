"use client";

import React, { useCallback, useEffect, useId, useState } from "react";

import "../Header.scss";

import useDebounce from "@hooks/useDebounce";
import useUpdateSearchParams from "@hooks/useUpdateSearchParams";
import { isAbsoluteUrl } from "@utils/is-absolute-url";

import { fetchSearchSuggestions } from "@services/search-service";
import HeaderSearchBarResults from "../Search/HeaderSearchBarResults";

const DEBOUNCE_DELAY = 1000;

interface Suggestion {
	type: "suggestion" | "path";
	value?: string;
	url?: string;
}

interface SearchBarProps {
	defaultValue?: string;
	id?: string;
	className?: string;
}

const HeaderSearchBar: React.FC<SearchBarProps> = ({
	defaultValue = "",
	id,
	className = "",
}) => {
	const { router, updateSearchParams } = useUpdateSearchParams();
	const [query, setQuery] = useState<string>(defaultValue || "");
	const [suggestions, setSuggestions] = useState<any>([]);
	const [isBlurred, setIsBlurred] = useState(false);
	const debouncedQuery = useDebounce(query, DEBOUNCE_DELAY);
	const generatedId = useId();
	const inputId = id || `search-bar-${generatedId}`;

	const handleInputChange = useCallback(async () => {
		if (debouncedQuery) {
			const data = await fetchSearchSuggestions(
				encodeURIComponent(debouncedQuery),
			);

			setSuggestions(query?.length >= 3 ? data : []);
		}
	}, [debouncedQuery, query]);

	useEffect(() => {
		if (query.length >= 3) {
			handleInputChange().catch((e) => console.debug(e));
		}
	}, [handleInputChange, query]);

	const handleSuggestionClick = useCallback(
		(suggestion: Suggestion) => {
			if (suggestion.type === "suggestion") {
				updateSearchParams({ q: suggestion.value }, "/search");
			} else if (suggestion.url) {
				const path = isAbsoluteUrl(suggestion.url) ? new URL(suggestion.url).pathname : suggestion.url;
				router.push(path || "/search");
			}
			setQuery("");
			setSuggestions([]);
		},
		[updateSearchParams, router],
	);

	const handleSearch = (e: React.FormEvent) => {
		e.preventDefault();
		updateSearchParams({ q: query }, "/search");
		setQuery("");
		setSuggestions([]);
	};

	const isExpanded = query.length >= 3 && !!suggestions.length && !isBlurred;

	return (
			<div
					className={`search-bar header-search-bar ${className} ${
							isExpanded ? "expanded" : ""
					}`}
			>
				<form onSubmit={handleSearch}>
					<label htmlFor={inputId} className="visually-hidden">
						Search Childrens.com
					</label>
					<div
							className="input-wrapper"
							role="combobox"
							aria-expanded={isExpanded}
							aria-controls={isExpanded ? "searchResults" : undefined}
							aria-haspopup="listbox"
					>
						<input
								placeholder="Search Childrens.com"
								id={inputId}
								name="q"
								type="search"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								onBlur={() => setTimeout(() => setIsBlurred(true), 300)}
								onFocus={() => setIsBlurred(false)}
								aria-autocomplete="list"
								autoComplete="off"
								spellCheck="false"
								dir="auto"
						/>
						<input className="search-submit" type="submit" />
					</div>
					{isExpanded && (
							<HeaderSearchBarResults
									id="searchResults"
									role="listbox"
									onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
									query={query}
									suggestions={suggestions}
									handleSuggestionClick={handleSuggestionClick}
							/>
					)}
				</form>
			</div>
	);
};

export default HeaderSearchBar;
