import React, { useMemo } from "react";
import { montserrat } from "@/utils/fonts";

const HighlightMatch = ({ text, wordHighlights }: any) => {
	const regex = useMemo(() => {
		return new RegExp(`(${wordHighlights})`, "gi");
	}, [wordHighlights]);

	const parts = text.split(regex);

	return (
		<div className='suggestion-text'>
			{parts.map((part: any, index: number) =>
				regex.test(part.toLowerCase()) ? (
					<b key={index}>{part}</b>
				) : (
					part
				),
			)}
		</div>
	);
};

const HeaderSearchBarResults = ({
	query,
	suggestions,
	handleSuggestionClick,
}: any) => {
	const handleClick = (type: any) => handleSuggestionClick(type);
	return (
		<>
			{query.length && suggestions.length ? (
				<div className={query.length >= 3 ? `suggestion-wrapper ${montserrat.className}` : ""}>
					{suggestions.map((suggestion: any, i: number) => (
						<div
							className='suggestion'
							key={i}
							onClick={() => handleClick(suggestion)}
						>
							{suggestion.type === "suggestion" ? (
								<div className='suggestion-search-link'>
									{
										<HighlightMatch
											text={suggestion.value}
											wordHighlights={query}
										/>
									}
								</div>
							) : (
								<div className='suggestion-page-link'>
									{
										<HighlightMatch
											text={suggestion.value}
											wordHighlights={query}
										/>
									}
									<span className='angle-right'></span>
								</div>
							)}
						</div>
					))}
				</div>
			) : null}
		</>
	);
};

export default HeaderSearchBarResults;
