import { usePathname, useRouter, useSearchParams } from "next/navigation";

interface Params {
	[key: string]: string | undefined;
}

const useUpdateSearchParams = () => {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const router = useRouter();

	const updateSearchParams = (
		paramsToUpdate: Params,
		route?: string | null,
	) => {
		if (!paramsToUpdate) {
			console.warn("paramsToUpdate is null or undefined");
		}

		if (!searchParams) {
			console.warn("searchParams is null or undefined");
		}
		if (!pathname) {
			console.warn("pathname is null or undefined");
		}

		const params = new URLSearchParams(searchParams as unknown as string);

		Object.entries(paramsToUpdate).forEach(([key, value]) => {
			if (value) {
				params.set(key, value);
			} else {
				params.delete(key);
			}
		});

		const queryString = params.toString();
		const newRoute = queryString
			? `${route ?? pathname}?${queryString}`
			: `${route ?? pathname}`;

		if (router && typeof router.replace === "function") {
			router.replace(newRoute);
		} else {
			console.error(
				"Router is not defined or does not have a replace function",
			);
		}
	};

	return {
		searchParams,
		pathname,
		router,
		updateSearchParams,
	};
};

export default useUpdateSearchParams;
