import React, { MutableRefObject, ReactNode, useEffect, useRef } from "react";

function useOutsideAlerter(
	ref: MutableRefObject<HTMLDivElement | null>,
	action: () => void,
): void {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent): void {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				action();
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, action]);
}

interface OutsideClickerProps {
	action: () => void;
	children: ReactNode;
}

const OutsideClicker: React.FC<OutsideClickerProps> = ({
	action,
	children,
}) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	useOutsideAlerter(wrapperRef, action);

	return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClicker;
