import React, { useMemo } from "react";
import Link from "next/link";

import ContentfulRichText from "@/components/ContentfulRichText/ContentfulRichText";
import { isEmptyObject } from "@/utils/is-empty-object";
import BynderImage from "@components/BynderImage/BynderImage";

const NavDropdown = ({ columnLinks }: any) => {
	const columns = useMemo(() => {
		const columnData = [[], [], []] as any;

		columnLinks.forEach((link: any) => {
			const { column = 1, ...fields } = link?.fields || {};
			if (column >= 1 && column <= 3) {
				columnData[column - 1].push(fields);
			}
		});

		return columnData;
	}, [columnLinks]);

	const NavItem = ({ link }: any) => {
		if (isEmptyObject(link)) return null;

		const hasChildLinks = link?.childLinks?.length > 0;
		if (link?.navImage?.length) {
			const descriptionRtf = link?.navImageDescription;
			return (
				<li className='nav-image' data-column={link.column}>
					<Link href={link.href}>
						<BynderImage bynderAsset={link.navImage[0]} />
						{descriptionRtf ? (
							<ContentfulRichText document={descriptionRtf} />
						) : null}
					</Link>
				</li>
			);
		}

		return (
			<>
				{link && !link?.mobileOnly ? (
					<li className={`${hasChildLinks ? "level-3-parent" : ""}`}>
						<Link className='level-2-link' href={link.href}>
							{link.title}
						</Link>
						{link.childLinks?.length > 0 && (
							<ul key={link.title} className='level-3'>
								{link.childLinks.map(
									(level3Link: any, idx: number) =>
										level3Link?.fields &&
										!level3Link?.fields?.mobileOnly ? (
											<li key={level3Link.sys.id}>
												<Link
													className='level-3-link'
													href={
														level3Link.fields.href
													}
												>
													{level3Link.fields.title}
												</Link>
											</li>
										) : null,
								)}
							</ul>
						)}
					</li>
				) : null}
			</>
		);
	};

	return (
		<div className='sub-nav-content'>
			{columns.map((col: any, columnIndex: number) => (
				<ul key={columnIndex} className='level-2-column'>
					{col.map((link: any, linkIndex: number) => (
						<NavItem
							key={linkIndex}
							link={link}
							isFirst={linkIndex === 0}
						/>
					))}
				</ul>
			))}
		</div>
	);
};

export default NavDropdown;
